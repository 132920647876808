define('ember-bootstrap-nav-link/components/nav-link-to', ['exports', 'ember-bootstrap-nav-link/templates/components/nav-link-to'], function (exports, _navLinkTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    tagName: 'li',
    layout: _navLinkTo.default,
    attributeBindings: ['data-toggle', 'data-target'],

    hrefForA: Ember.computed('models', 'qualifiedRouteName', function computeLinkToComponentHref() {
      let qualifiedRouteName = this.get('qualifiedRouteName');
      let models = this.get('models');

      if (this.get('loading')) {
        return this.get('loadingHref');
      }

      let routing = this.get('_routing');
      let queryParams = this.get('queryParams.values');
      return routing.generateURL(qualifiedRouteName, models, queryParams);
    })
  });
});