define('ember-portal/components/portal-for', ['exports', 'ember-component', 'ember-portal/utils/portal-id'], function (exports, _emberComponent, _emberPortalUtilsPortalId) {
  exports['default'] = _emberComponent['default'].extend({
    name: 'default',

    'portal-class': null,

    portalElement: function portalElement() {
      var elementID = (0, _emberPortalUtilsPortalId['default'])(this.get("name"));
      var element = document.getElementById(elementID);

      if (!element) {
        element = document.createElement('div');
        element.id = elementID;
      }

      return element;
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var portal = this.portalElement();
      portal.style.display = null;

      var portalClass = this.get("portal-class");
      if (portalClass && !portal.className) {
        portal.className = portalClass;
      }

      this.get("element").appendChild(portal);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var portal = this.portalElement();
      portal.style.display = 'none';
      document.body.appendChild(portal);
    }

  });
});