define('ember-pouch/transforms/attachment', ['exports', 'ember-pouch/transforms/attachments'], function (exports, _attachments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    isNone
  } = Ember;

  exports.default = _attachments.default.extend({
    deserialize: function (serialized) {
      return this._super(serialized).pop();
    },
    serialize: function (deserialized) {
      if (isNone(deserialized)) {
        return null;
      }
      return this._super([deserialized]);
    }
  });
});