define('ember-cli-fastclick/initializers/fastclick', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    if (typeof FastBoot === 'undefined') {
      Ember.run.schedule('afterRender', function () {
        FastClick.attach('body');
      });
    }
  }

  exports.default = {
    initialize
  };
});