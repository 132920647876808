define('ember-portal/components/portal-content', ['exports', 'ember-component', 'ember-service/inject', 'ember-computed', 'ember-portal/utils/portal-id'], function (exports, _emberComponent, _emberServiceInject, _emberComputed, _emberPortalUtilsPortalId) {
  exports['default'] = _emberComponent['default'].extend({

    portalService: (0, _emberServiceInject['default'])("portal"),

    'for': "default",

    items: (0, _emberComputed['default'])("for", {
      get: function get() {
        return this.get("portalService").itemsFor(this.get("for"));
      }
    }),

    wormholeName: (0, _emberComputed['default'])("for", {
      get: function get() {
        return (0, _emberPortalUtilsPortalId['default'])(this.get("for"));
      }
    }),

    showingPortalItem: (0, _emberComputed['default'])("items.length", {
      get: function get() {
        return this.get("items.lastObject") === this;
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupWormholeElement();
      this.get("portalService").addPortalContent(this.get("for"), this);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get("portalService").removePortalContent(this.get("for"), this);
    },

    setupWormholeElement: function setupWormholeElement() {
      var id = this.get("wormholeName");
      if (document.getElementById(id)) {
        return;
      }

      var rootEl = document.body;
      var stackElement = document.createElement('div');
      stackElement.id = id;
      stackElement.style.display = 'none';

      rootEl.appendChild(stackElement);
    }

  });
});