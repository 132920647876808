define('ember-pouch/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.extractDeleteRecord = extractDeleteRecord;
  exports.shouldSaveRelationship = shouldSaveRelationship;
  exports.configFlagDisabled = configFlagDisabled;
  exports.configFlagEnabled = configFlagEnabled;


  // ember-data doesn't like getting a json response of {deleted: true}
  function extractDeleteRecord() {
    return null;
  }

  //should this take a config?
  function shouldSaveRelationship(container, relationship) {
    if (typeof relationship.options.save !== "undefined") return relationship.options.save;

    if (relationship.kind === 'belongsTo') return true;

    //TODO: save default locally? probably on container?
    let saveDefault = configFlagEnabled(container, 'saveHasMany'); //default is false if not specified

    return saveDefault;
  }

  function configFlagDisabled(container, key) {
    //default is on
    let config = Ember.getOwner(container).resolveRegistration('config:environment');
    let result = config['emberPouch'] && typeof config['emberPouch'][key] !== 'undefined' && !config['emberPouch'][key];

    return result;
  }

  function configFlagEnabled(container, key) {
    //default is off
    let config = Ember.getOwner(container).resolveRegistration('config:environment');
    let result = config['emberPouch'] && config['emberPouch'][key];

    return result;
  }
});