define('ember-portal/services/portal', ['exports', 'ember-service', 'ember-runloop', 'ember'], function (exports, _emberService, _emberRunloop, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var ADDED_QUEUE = _ember['default'].A();
  var REMOVED_QUEUE = _ember['default'].A();

  exports['default'] = _emberService['default'].extend({
    portals: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("portals", {});
    },

    itemsFor: function itemsFor(name) {
      var portals = this.get("portals");
      var items = portals[name];
      if (!items) {
        items = _ember['default'].A();
        portals[name] = items;
      }
      return items;
    },

    addPortalContent: function addPortalContent(name, component) {
      if (this.addToQueueInReverse()) {
        ADDED_QUEUE.unshift({ name: name, component: component });
      } else {
        ADDED_QUEUE.push({ name: name, component: component });
      }

      (0, _emberRunloop.scheduleOnce)("afterRender", this, this.flushPortalContent);
    },

    // run after render to avoid warning that items were modified on didInsertElement hook
    flushPortalContent: function flushPortalContent() {
      var _this = this;

      ADDED_QUEUE.forEach(function (_ref) {
        var name = _ref.name;
        var component = _ref.component;

        _this.itemsFor(name).pushObject(component);
      });
      ADDED_QUEUE.clear();

      REMOVED_QUEUE.forEach(function (_ref2) {
        var name = _ref2.name;
        var component = _ref2.component;

        _this.itemsFor(name).removeObject(component);
      });
      REMOVED_QUEUE.clear();
    },

    removePortalContent: function removePortalContent(name, component) {
      REMOVED_QUEUE.push({ name: name, component: component });
      (0, _emberRunloop.scheduleOnce)("afterRender", this, this.flushPortalContent);
    },

    // prior to 1.13.x components are inserted in reverse order
    addToQueueInReverse: function addToQueueInReverse() {
      var _Ember$VERSION$split$map = _ember['default'].VERSION.split('.').map(function (i) {
        return parseInt(i, null);
      });

      var _Ember$VERSION$split$map2 = _slicedToArray(_Ember$VERSION$split$map, 2);

      var maj = _Ember$VERSION$split$map2[0];
      var min = _Ember$VERSION$split$map2[1];

      return maj === 1 && min < 13;
    }
  });
});